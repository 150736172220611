<template>
  <div>
    <div v-if="loading" class="loading-container pt-12">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </div>
    <v-form
      @submit.prevent="save"
      ref="form"
      v-show="!loading"
      :disabled="$attrs.disabled"
    >
      <ErrorMessages :messages="errors"></ErrorMessages>
      <v-row class="max-width">
        <v-col>
          <v-text-field
            v-model="form.slug"
            :rules="$rules.required"
            label="Slug"
            required
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-col>
          <v-select
            label="Tipo"
            :items="kinds"
            v-model="form.schedule"
            :rules="$rules.required"
            required
            dense
            outlined
          ></v-select>
        </v-col>
      </v-row>
      <v-row class="max-width">
        <v-col>
          <v-textarea
            v-model="form.parameters"
            label="Parâmetros"
            required
            dense
            outlined
          ></v-textarea>
        </v-col>
      </v-row>
      <slot></slot>
    </v-form>
  </div>
</template>
<style scoped>
.max-width {
  max-width: 600px;
}
</style>
<script>
import Api from '@/resources/NajaSocialApi'
import ErrorMessages from '@/components/ErrorMessages'

export default {
  name: 'SlugForm',
  components: { ErrorMessages },
  data: () => ({
    saveLoading: false,
    loading: false,
    tab: 0,
    errors: [],
    kinds: [
      { text: 'Consulta', value: 'appointment' },
      { text: 'Exame', value: 'exam' },
    ],
    form: {
      parameters: null,
      schedule: null,
      slug: null,
    },
  }),

  mounted() {
    this.fetch(this.$route.params.id)
  },

  methods: {
    validate(field) {
      const errorMsg =
        this.errors[field] == null ? false : this.errors[field].join(',')
      if (errorMsg) {
        return [value => !!value || errorMsg]
      }

      return []
    },

    remove() {
      this.$refs.removeQuestionDialog.open({
        onConfirm: () => {
          this.$refs.removeQuestionDialog.toggleLoading()
          Api.roles
            .destroy(this.$route.params.id)
            .then(async () => {
              this.$router.push({ name: 'Slugs' })
            })
            .catch(error => {
              if (error.response) {
                this.$toast.show('Erro ao remover Slug', 'error')
                // this._setErrors(error.response.data.errors);
              } else {
                throw error
              }
            })
            .finally(() => {
              this.$refs.removeQuestionDialog.close()
            })
        },
      })
    },

    _parseForm(data) {
      const formPayload = {
        id: data.id,
        slug: data.slug,
        schedule: data.schedule,
        parameters: data.parameters,
      }

      this.form = { ...this.form, ...formPayload }
    },

    async fetch(id = null) {
      try {
        this.loading = true

        if (id === null) {
          return
        }

        const slugsResponse = await Api.slugs.fetch(id)
        this._parseForm(slugsResponse.data.slug)
      } catch (e) {
        this.$root.$children[0].toast('Erro ao buscar Slug')
        throw e
      } finally {
        this.$scrollTop()
        this.loading = false
      }
    },

    save() {
      this.saveLoading = true

      Api.slugs
        .save(this.$route.params.id, {
          slug: this.form,
        })
        .then(response => {
          this._parseForm(response.data.slug)
          this.$root.$children[0].toast('Slug salvo com sucesso')

          this.$router.push({
            name: 'SlugShow',
            params: { id: this.form.id },
          })
        })
        .catch(error => {
          if (error.response) {
            this.$root.$children[0].toast('Erro ao salvar Slug')
            if (error.response.status != 500) {
              this.errors = error.response.data.errors

              this.$refs.form.validate()
            }
          } else {
            throw error
          }

          // throw error;
        })
        .finally(() => {
          this.$scrollTop()
          this.saveLoading = false
        })
    },
  },
}
</script>
